import React, { useState } from "react";
import classNames from "classnames";
import styles from "./MerchantInfoBox.scss";
import {
  ADDITIONAL_INFO_HEADERS,
  ADDITIONAL_INFO_ICONS,
  ADDITIONAL_INFO_KEYS,
  ADDITIONAL_INFO_TOOLTIPS
} from "../../MerchantTabular/common/constants";
import ProfileInfoCard from "../../MerchantTabular/common/ProfileInfoCard";

const renderContactInfo = merchantDetail => {
  const properties = [
    {
      key: "Ticaret Ünvanı",
      val:
        merchantDetail?.merchantOptions &&
        merchantDetail?.merchantOptions?.brandName !== "" &&
        merchantDetail?.merchantOptions?.brandName !== null
          ? merchantDetail?.merchantOptions?.brandName
          : merchantDetail?.legalName || "-"
    },
    { key: "Kayıtlı E-Posta Adresi", val: merchantDetail?.kep || "-" },
    {
      key: "VKN",
      val: merchantDetail?.financialInformation?.financialIdentityNumber || "-"
    },
    { key: "Mersis No", val: merchantDetail?.mersisNumber || "-" },
    {
      key: "Merkez Adresi",
      val:
        merchantDetail?.billingAddress?.cityName?.charAt(0)?.toUpperCase() +
          merchantDetail?.billingAddress?.cityName?.slice(1)?.toLowerCase() || "-"
    },
    {
      key: "İletişim",
      val:
        "Satıcı firmanın onaylanmış elektronik iletişim adresi ve doğrulanan merkez adresi Hepsiburada.com kayıtlarında yer almaktadır."
    }
  ];

  return properties.map(
    (p, i) =>
      p?.val && (
        <p key={i}>
          <span>{p.key}</span> {p.val}
        </p>
      )
  );
};

const MerchantInfoBox = ({ initialState }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { data: merchantDetail } = initialState.data;
  const isWomenEnt = merchantDetail?.tagList?.some(i =>
    ["woman-entrepreneur", "woman-entrepreneur-logo-biography"].includes(i)
  );
  const handleClick = () => setIsOpen(val => !val);
  return (
    <div
      className={classNames(styles.root, {
        [styles.open]: isOpen
      })}
      data-testid='root'
    >
      <div className={styles.head} onClick={handleClick} data-testid='head'>
        <p className={styles.headerText} id='About-seller'>
          Satıcı Hakkında
        </p>
        <div className={styles.arrow} />
      </div>
      <div className={styles.body}>
        <div className={styles.addInfoWrapper}>
          {Object.keys(ADDITIONAL_INFO_KEYS).map(key => (
            <ProfileInfoCard
              key={key}
              header={ADDITIONAL_INFO_HEADERS[key]}
              value={merchantDetail?.additionalInfo?.[key]}
              Icon={ADDITIONAL_INFO_ICONS[key]}
              tooltip={ADDITIONAL_INFO_TOOLTIPS[key] || ""}
              merchantDetail={merchantDetail}
              isMobile
            />
          ))}
        </div>
        {isWomenEnt && (merchantDetail?.profileImage || merchantDetail?.description) && (
          <div className={styles.descArea}>
            {merchantDetail.profilePicture && (
              <div className={styles.profilePic}>
                <img src={merchantDetail.profilePicture} alt='profile-picture' />
              </div>
            )}
            <div>
              {merchantDetail?.nameAndSurname && (
                <p className={styles.fullName}>
                  {merchantDetail?.nameAndSurname}
                  {merchantDetail?.profession && " / " + merchantDetail.profession}
                </p>
              )}
              {merchantDetail?.description && <p>{merchantDetail.description}</p>}
            </div>
          </div>
        )}
        <div className={styles.contacts}>
          {merchantDetail?.logoUrl && (
            <div className={styles.logo}>
              <img src={merchantDetail.logoUrl} alt='logo-url' />
            </div>
          )}
          <div className={styles.contactInfo}>{renderContactInfo(merchantDetail)}</div>
        </div>
      </div>
    </div>
  );
};

export default MerchantInfoBox;
