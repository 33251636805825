import styles from "./AboutSeller.scss";

const voltran = require("/home/node/app/node_modules/voltranjs/src/index");
// Components
import React from "react";
// Services
import { getMerchantDataByIdInternal } from "../../../services/merchantService";
//components
import MerchantInfoBox from "./components/MerchantInfoBox";
import HModule from "@cosmos/h-module";
//utils
import ROUTE_PATHS from "../../../routes/routeConstants";
import { checkMerchantIdExists, checkMerchantIsActiveOrNot } from "../MerchantRow/helpers/merchant";
import { throwError } from "../../../utils/errorHandler/errorHandler";
import { hermesVoltranExternalUrl } from "../../../services/urls";

const AboutSeller = ({ initialState }) => {
  const { data: merchantDetail } = initialState.data;
  return (
    <>
      <MerchantInfoBox initialState={initialState} />
      <div id='productReviews' className={styles.productReviews}>
        <HModule
          url={`${hermesVoltranExternalUrl}/productReviews?merchantId=${merchantDetail?.id}`}
          name='productReviews'
        />
      </div>
    </>
  );
};

const component = voltran.default.withBaseComponent(AboutSeller, ROUTE_PATHS.ABOUTSELLER);

component.services = [voltran.default.SERVICES.merchantContentApi];

component.getInitialState = async (voltranApiManager, context) => {
  const { merchantId } = context.query;

  checkMerchantIdExists(merchantId, context);

  try {
    const { data } = await getMerchantDataByIdInternal(merchantId, voltranApiManager, context.cookies);
    if (data) {
      const merchantData = { merchantDetail: data.data };
      checkMerchantIsActiveOrNot(merchantData);
      return { ...data };
    }
  } catch (error) {
    throwError(error, context);
  }
};

export default component;
